import {http} from '@/core/ApiConfig';
import { Service } from '../shared';

export class NfeService extends Service {

    constructor(){
        super('nfe');
    }

    GetClassStatus(statusId: number): String{

        switch (statusId) {
            case 1: //Enviada
                return 'dfeEnviado';
            case 2: //Autorizada
                return 'dfeAutorizado';
            case 3: //Cancelada
                return 'dfeCancelado';
            case 4: //Rejeitada
                return 'dfeRejeitado';
            case 5: //Denegada
                return 'dfeDenegado';
            default:
                return '';
        }
    }
    
    public ObterTotal(emitenteId: string, dataInicial: string, dataFinal: string, statusId?: number) : Promise<any> {
        
        const dataHoraFinal = `${dataFinal}T23:59:59`;

        return http.get(`${this._nomeControle}/totais`, {
            params: {
                dataInicial,
                dataFinal : dataHoraFinal,
                statusId
            },
            headers: this.GetHeaderValue(emitenteId)
        });

    }

    public Obter(emitenteId: string, dataInicial: string, dataFinal: string, numeroInicial?: number, numeroFinal?: number, statusId?: number) : Promise<any> {
        
        const dataHoraFinal = `${dataFinal}T23:59:59`;

        return http.get(`${this._nomeControle}`, {
            params: {
                dataInicial,
                dataFinal : dataHoraFinal,
                numeroInicial,
                numeroFinal,
                statusId
            },
            headers: this.GetHeaderValue(emitenteId)
        });

    }

    public DanfePdf(emitenteId: string, serie: number, numero: number){

        return http.get(`${this._nomeControle}/danfe`, {
            responseType: 'arraybuffer',
            params: {
                serie: serie,
                numero: numero
            },
            headers: {
                'Accept': 'application/pdf',
                'emitenteId': emitenteId
              },
        });

    }

    public DownloadXml(emitenteId: string, serie: number, numero: number){

      return http.get(`${this._nomeControle}/xml`, {
          responseType: 'blob',
          headers: {
            'emitenteId': emitenteId
          },
          params: {
              serie: serie,
              numero: numero
          }
      });
    }

    public DownloadXml2(emitenteId: string, ids: string[]){

        let paramIds = `?ids=${ids.join('&ids=')}`;

        return http.get(`${this._nomeControle}/xml2${paramIds}`, {
            responseType: 'blob',
            headers: {
              'emitenteId': emitenteId
            }
        });
      }

}