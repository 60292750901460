import { TableToExcel } from "@/assets/scripts/helper";
import { Vue } from "vue-property-decorator";

export class PageBase extends Vue{

    constructor(){

        super();

        if(!localStorage.sessionApp){
            //this.$router.push({name: 'login'});
            return;
        }

        this.AtualizarApp();

    }

    get app() { 
        return this.$store.getters.app
    }

    public AtualizarApp(){

        this.$store.dispatch('AtualizarApp');

    }

    ExportarExcel(tabela: string, worksheet?: string){
        TableToExcel(tabela, worksheet);
    }

    MudarTema(temaEscuro: boolean){
        // this.app.temaEscuro = temaEscuro;
        // this.sessionApp.dados.temaEscuro = temaEscuro;
        // localStorage.setItem('sessionApp', JSON.stringify(this.sessionApp));
        // this.$vuetify.theme.dark = temaEscuro;
    }

}