






































































































import { Component, Watch } from "vue-property-decorator";
import { PageBase, TotalQuantidadeModel } from "@/core/models/shared/"
import { NfeService } from '@/core/services/dfe';

@Component
export default class Home extends PageBase {

    nfeService: NfeService = new NfeService();
    totalGeral: TotalQuantidadeModel = new TotalQuantidadeModel();
    totalAutorizada: TotalQuantidadeModel = new TotalQuantidadeModel();
    totalCancelada: TotalQuantidadeModel = new TotalQuantidadeModel();
    totalRejeitada: TotalQuantidadeModel = new TotalQuantidadeModel();
    totalDenegada: TotalQuantidadeModel = new TotalQuantidadeModel();
    totalEnviada: TotalQuantidadeModel = new TotalQuantidadeModel();
    data = new Date();
    item = {
        dataInicial: new Date(this.data.getFullYear(), this.data.getMonth(), 1).toYYYYMMDD(),
        dataFinal: new Date(this.data.getFullYear(), this.data.getMonth() + 1, 0).toYYYYMMDD()
    }

    @Watch('app.emitenteId')
    onApp(){
        this.Consultar();
    }

    Consultar(){

        if (!this.app.emitenteId){
            this.$swal('Aviso', 'Selecione um emitente!', 'info');
            return;
        }

        this.nfeService.ObterTotal(this.app.emitenteId, this.item.dataInicial, this.item.dataFinal).then(
            res => this.totalGeral = res.data
        );

        this.nfeService.ObterTotal(this.app.emitenteId, this.item.dataInicial, this.item.dataFinal, 1).then(
            res => this.totalEnviada = res.data
        );

        this.nfeService.ObterTotal(this.app.emitenteId, this.item.dataInicial, this.item.dataFinal, 2).then(
            res => this.totalAutorizada = res.data
        );

        this.nfeService.ObterTotal(this.app.emitenteId, this.item.dataInicial, this.item.dataFinal, 3).then(
            res => this.totalCancelada = res.data
        );

        this.nfeService.ObterTotal(this.app.emitenteId, this.item.dataInicial, this.item.dataFinal, 4).then(
            res => this.totalRejeitada = res.data
        );

        this.nfeService.ObterTotal(this.app.emitenteId, this.item.dataInicial, this.item.dataFinal, 5).then(
            res => this.totalDenegada = res.data
        );

    }

    mounted() {

        this.Consultar();

    }


}
