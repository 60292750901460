import Vue from 'vue';
import MasterPage from "./shared/MasterPage.vue"

import SelecionarEmitente from "./shared/SelecionarEmitente.vue"
import CardDFe from "./shared/CardDFe.vue"

//--------------------------------------------------------------------------------------------------------------------------------------------------------------------------//

Vue.component("master-page", MasterPage);

 Vue.component("selecionar-emitente", SelecionarEmitente);
 Vue.component("card-dfe", CardDFe);
// Vue.component("cadastro-usuario", CadastroUsuario);
// Vue.component("cadastro-empresa", CadastroEmpresa);
// Vue.component("cadastro-chamado", CadastroChamado);
// Vue.component("atendimento-chamado", AtendimentoChamado);