















import { Component } from "vue-property-decorator";
import { PageBase } from "@/core/models/shared/"

@Component
export default class Home extends PageBase {

  mounted() {
    
  }


}
