import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
      app : { //: {emitenteId: number, emitenteLogo: any, emitenteNome: string} = {
        emitenteId: 0, 
        emitenteLogo: '', 
        emitenteNome: ''
    }
  },
  getters: {
    app: (state) => state.app
  },
  mutations: {
    atualizarApp(state, app) {
        state.app = app;
    }
  },
  actions: {
    SetApp(context, app){

        localStorage.clear();
        localStorage.setItem('sessionApp', JSON.stringify(app));

        context.commit('atualizarApp', app);

    },
    AtualizarApp(context){

        let sessionApp = JSON.parse(localStorage.sessionApp);;

        context.commit('atualizarApp', sessionApp);
        
    }
  },
  modules: {
  }
})
