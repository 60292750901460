import Vue from 'vue'
import Vuex from 'vuex'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueSweetAlert2 from 'vue-sweetalert2'
//import 'sweetalert2/dist/sweetalert2.min.css';
import './assets/scripts/formatter'

Vue.use(Vuex);
Vue.use(VueSweetAlert2);

require('./components/');

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
