export class ServiceBase {

  protected _nomeControle: string = '';

  constructor(nomeControle: string){
      this._nomeControle = nomeControle;
  }

  protected GetHeader(emitenteId?: string, include?: string){
    let header = {
      headers: this.GetHeaderValue(emitenteId, include)
    };

    return header;
  }

  protected GetHeaderValue(emitenteId?: string, include?: string){
    
    let header = {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      "include": "",
      "emitenteId": ""
    };

    if (emitenteId) {
      header.emitenteId = emitenteId;
    }

    if (include) {
      header.include = include;
    }

    return header;
  }

  public GetNomeControle() : string{
    return this._nomeControle;
  }

}