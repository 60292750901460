import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import { colors } from 'vuetify/lib';
import pt from 'vuetify/src/locale/pt';

Vue.use(Vuetify);

export default new Vuetify({
    lang: {
      locales: { pt },
      current: 'pt',
    },
    theme: {
      dark: false,
      default: 'light',
      themes: { 
        light: {
          primary: colors.blue.darken2,
          secondary: colors.blue.lighten2,
          toolbar: '#d3dade',
          soliticacaoAberto: '#eb8f8f',
          widget: colors.blueGrey.lighten5,
          cartaoCancelado: '#eb8f8f',
          changed: colors.deepOrange.lighten4,
          dfeEnviado: colors.lightBlue.lighten4,
          dfeAutorizado: colors.lightGreen.lighten4,
          dfeCancelado: colors.red.lighten4,
          dfeRejeitado: colors.orange.lighten4,
          dfeDenegado: colors.blueGrey.lighten4,
        },
        dark: {
          primary: '#78909C',
          secondary: '#424242',
          toolbar: '#4f4f4f',
          soliticacaoAberto: '#430a0a',
          widget: colors.blueGrey.darken3,
          cartaoCancelado: '#430a0a',
          changed: colors.deepOrange.darken3
        }
       },
      }
});
