






























































































































// import { Empresa } from "@/core/models/geral";
import { PageBase } from "@/core/models/shared";
// import { UsuarioService } from "@/core/services/geral";
// import { AutenticadorService } from "@/core/services/shared";
import { EmitenteService } from '@/core/services/geral';
import { Component, Prop, Watch, Vue } from "vue-property-decorator";

@Component
export default class MasterPage extends PageBase {
    @Prop() private icone!: string;
    @Prop() private titulo!: string;
    @Prop() private subTitulo!: string;

    drawer = false;
    active = false;

    service: EmitenteService = new EmitenteService();
    selectEmitente: boolean = false;
    empresaSelecao: any = {};
    emitentes: any[] = [];

    // usuarioService: UsuarioService = new UsuarioService();
    // autenticadorService: AutenticadorService = new AutenticadorService();

    mounted() {
        this.$vuetify.theme.dark = this.app.temaEscuro;

        // this.empresaId = this.app.empresaId;
        // this.usuarioService.ObterPorId(this.app.usuarioId, "Empresas.Empresa").then(
        //     res=>{
        //         const usuarioEmpresa = res.data.empresas;                
        //         usuarioEmpresa.forEach((x:any) => {
        //             this.empresas.push(x.empresa);
        //         });
        //     }    
        // )     
        
    }

    Logout() {
        localStorage.clear();
        this.$vuetify.theme.dark = false;
        this.$router.push({name: 'login'});
    }

    MudarTema(){
        // const usuarioService = new UsuarioService();
        // usuarioService.MudarTema(this.app.usuarioId).then(
        //     res => super.MudarTema(res.data),
        //     err => this.$swal('Aviso', err.message, 'error')
        // );
    };

    MudarEmitente(){

        this.selectEmitente = true;
        
        // this.autenticadorService.TrocarEmpresa(this.app.usuarioId,this.empresaId).then(
        //     res =>{
        //         localStorage.clear()
        //         localStorage.setItem('sessionApp', JSON.stringify(res.data));
        //         this.$swal('Empresa Alterada', '', res.status == 200 ? 'success' : 'warning');
        //         document.location.reload();
        //     }
        // )
    }

    AlterarSenha(){
      
    //   this.$swal({
    //     title: 'Digite sua senha atual',
    //     input: 'password',
    //     confirmButtonText: 'Próximo &rarr;',
    //   })
    //   .then((senhaAntiga: any) => {

    //     this.$swal({
    //     title: 'Digite sua senha nova',
    //     input: 'password',
    //     confirmButtonText: 'Próximo &rarr;',
    //     })
    //     .then((senhaNova: any) => {

    //         this.$swal({
    //         title: 'Repita a senha nova',
    //         input: 'password',
    //         confirmButtonText: 'Alterar',
    //         showLoaderOnConfirm: true,
    //         // @ts-ignore: Unreachable code error
    //         allowOutsideClick: () => !this.$swal.isLoading()
    //         })
    //         .then((senhaNova2: any) => {

    //         if (!senhaAntiga.value || !senhaNova.value){
    //             this.$swal("Aviso", 'Nâo foi possível alterar a senha!', "info");
    //             return;
    //         }

    //         if (senhaNova.value != senhaNova2.value){
    //             this.$swal("Aviso", 'As senhas não conferem!', "warning");
    //             return;
    //         }

    //         const usuarioService = new UsuarioService();
    //         usuarioService.AlterarSenha(this.app.usuarioId, senhaAntiga.value, senhaNova.value)
    //             .then(
    //                 res => {
    //                     this.$swal("Aviso", res.data, res.status == 200 ? "success" : "warning");
    //                 },
    //                 err => {
    //                     this.$swal('Aviso', err.message, 'error')}
    //             );
    //         });
    //     })
    //   })
  }

}

