



















import { PageBase } from "@/core/models/shared";
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class CardDFe extends PageBase {

  @Prop() private titulo!: string;
  @Prop() private subTitulo!: string;
  @Prop() private corFundo!: string;
  @Prop() private corIcone!: string;
  @Prop() private icone!: string;
  @Prop() private total!: number;

}
