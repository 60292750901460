import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '../views/Home.vue'
import HomeContador from '../views/contador/Home.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  { path: '/', name: 'Home', component: Home },
  { path: '/contador/home', name: 'HomeContador', component: HomeContador },

  { path: "/nfe", name: "nfe", component: () => import("../views/nfe/Lista.vue") },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
