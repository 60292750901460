






























//import { AutenticadorService } from '@/core/services/shared';
import { PageBase } from "@/core/models/shared";
import { EmitenteService } from '@/core/services/geral';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';

@Component
export default class SelecionarEmitente extends PageBase {
  @Prop() private value!: string;
  @Prop() private item!: any;
  @Prop() private acesso!: any;

  dialog: boolean = false;
  //service: AutenticadorService = new AutenticadorService();
  service: EmitenteService = new EmitenteService();
  emitentes: any[] = [];
  emitenteId = '';
  emitenteNome = '';

  @Watch("value")
  Value() {
    this.dialog = this.value ? true : false;

    if (this.value){
        this.service.ListarTudoOrdenado("razaoSocial").then(
            res => {
                this.emitentes = res.data;
            }
        );
    }

  }

  EmitenteSelected(emitente: any){

    let sessionApp = {
        emitenteId : emitente.id,
        emitenteNome : emitente.razaoSocial
    }

    this.$store.dispatch('SetApp', sessionApp);
    this.$swal('Aviso', 'Emitente alterado com sucesso!', 'success');
    this.Close();

  }

  Close() {
    this.$emit("fechou");
    this.dialog = false;
  }

}
